var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _vm.code !== "view"
        ? _c(
            "vxe-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              on: { click: _vm.addActivity },
            },
            [_vm._v("新增活动大类")]
          )
        : _vm._e(),
      _vm._l(_vm.detailVos, function (item, k) {
        return _c(
          "div",
          { key: k, staticClass: "list-wrap" },
          [
            _c(
              "el-form",
              {
                ref: "detailForm" + k,
                refInFor: true,
                staticClass: "demo-ruleForm",
                attrs: {
                  model: item,
                  rules: _vm.detailRule,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "预算科目", prop: "name" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cost-select",
                                on: {
                                  click: function ($event) {
                                    return _vm.openHandleClick(
                                      "budgetSubjects",
                                      "tpm_budget_subjects_list",
                                      "budgetSubjectsCode",
                                      k,
                                      null,
                                      { subjectsTypeCode: "receive" }
                                    )
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    readonly: true,
                                    placeholder: "预算科目",
                                  },
                                  model: {
                                    value: item.ext19,
                                    callback: function ($$v) {
                                      _vm.$set(item, "ext19", $$v)
                                    },
                                    expression: "item.ext19",
                                  },
                                }),
                                _vm.code !== "view"
                                  ? _c("div", { staticClass: "clear-icon" }, [
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFn(
                                              $event,
                                              "budgetSubjects",
                                              k
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "活动大类",
                              prop: "categoriesName",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cost-select",
                                on: {
                                  click: function ($event) {
                                    return _vm.openHandleClick(
                                      "category",
                                      "tpm_category_list",
                                      "categoriesCode",
                                      k,
                                      null,
                                      {
                                        budgetSubjectsCode:
                                          item.budgetSubjectsCode,
                                      }
                                    )
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    readonly: true,
                                    placeholder: "请选择活动大类",
                                  },
                                  model: {
                                    value: item.categoriesName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "categoriesName", $$v)
                                    },
                                    expression: "item.categoriesName",
                                  },
                                }),
                                _vm.code !== "view"
                                  ? _c("div", { staticClass: "clear-icon" }, [
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFn(
                                              $event,
                                              "category",
                                              k
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动细类", prop: "fineName" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cost-select",
                                on: {
                                  click: function ($event) {
                                    return _vm.openHandleClick(
                                      "fine",
                                      "tpm_fine_list",
                                      "fineCode",
                                      k,
                                      null,
                                      { categoriesCode: item.categoriesCode }
                                    )
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    readonly: true,
                                    placeholder: "请选择活动大类",
                                  },
                                  model: {
                                    value: item.fineName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "fineName", $$v)
                                    },
                                    expression: "item.fineName",
                                  },
                                }),
                                _vm.code !== "view"
                                  ? _c("div", { staticClass: "clear-icon" }, [
                                      _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearFn(
                                              $event,
                                              "fine",
                                              k
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.code !== "view"
                      ? _c(
                          "el-col",
                          { attrs: { span: 3 } },
                          [
                            _c("el-button", {
                              staticStyle: { "margin-left": "100px" },
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteActivity(k)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.code !== "view"
                  ? _c(
                      "vxe-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        on: {
                          click: function ($event) {
                            return _vm.addDetail(k, item)
                          },
                        },
                      },
                      [_vm._v("新增明细")]
                    )
                  : _vm._e(),
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "card", closable: _vm.code !== "view" },
                    on: {
                      "tab-remove": function ($event) {
                        return _vm.removeTab($event, k)
                      },
                    },
                    model: {
                      value: item.editableTabsValue,
                      callback: function ($$v) {
                        _vm.$set(item, "editableTabsValue", $$v)
                      },
                      expression: "item.editableTabsValue",
                    },
                  },
                  _vm._l(item.activityInfo, function (act, i) {
                    return _c(
                      "el-tab-pane",
                      {
                        key: act.tabValue,
                        attrs: {
                          label: "明细表单" + (i + 1),
                          name: act.tabValue,
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动明细名称",
                                      prop: "ext1",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "活动明细名称" },
                                      model: {
                                        value: act.ext1,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext1", $$v)
                                        },
                                        expression: "act.ext1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动明细编码",
                                      prop: "actDetailCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "活动明细编码",
                                      },
                                      model: {
                                        value: act.actDetailCode,
                                        callback: function ($$v) {
                                          _vm.$set(act, "actDetailCode", $$v)
                                        },
                                        expression: "act.actDetailCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "公司主体", prop: "ext3" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cost-select",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openHandleClick(
                                              "company",
                                              "company_list",
                                              "companyCode",
                                              k,
                                              i,
                                              null
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            readonly: true,
                                            placeholder: "公司主体",
                                          },
                                          model: {
                                            value: act.ext3,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext3", $$v)
                                            },
                                            expression: "act.ext3",
                                          },
                                        }),
                                        _vm.code !== "view"
                                          ? _c(
                                              "div",
                                              { staticClass: "clear-icon" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clearFn(
                                                        $event,
                                                        "company",
                                                        k,
                                                        i
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "受益经销商",
                                      prop: "ext7",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        readonly: true,
                                        placeholder: "受益经销商",
                                      },
                                      model: {
                                        value: act.ext7,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext7", $$v)
                                        },
                                        expression: "act.ext7",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "受益组织", prop: "ext5" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cost-select",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openHandleClick(
                                              "org",
                                              "org_list",
                                              "orgCode",
                                              k,
                                              i,
                                              null
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            readonly: true,
                                            placeholder: "受益组织",
                                          },
                                          model: {
                                            value: act.ext5,
                                            callback: function ($$v) {
                                              _vm.$set(act, "ext5", $$v)
                                            },
                                            expression: "act.ext5",
                                          },
                                        }),
                                        _vm.code !== "view"
                                          ? _c(
                                              "div",
                                              { staticClass: "clear-icon" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clearFn(
                                                        $event,
                                                        "org",
                                                        k,
                                                        i
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "申请金额",
                                      prop: "applyAmount",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "申请金额",
                                      },
                                      model: {
                                        value: act.applyAmount,
                                        callback: function ($$v) {
                                          _vm.$set(act, "applyAmount", $$v)
                                        },
                                        expression: "act.applyAmount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动明细内容",
                                      prop: "ext13",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: "活动明细内容",
                                      },
                                      model: {
                                        value: act.ext13,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext13", $$v)
                                        },
                                        expression: "act.ext13",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "收货人", prop: "ext14" } },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "收货人" },
                                      model: {
                                        value: act.ext14,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext14", $$v)
                                        },
                                        expression: "act.ext14",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "联系方式", prop: "ext15" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        oninput:
                                          "value=value.replace(/[^\\d]/g,'')",
                                        maxLength: "11",
                                        placeholder: "收货人联系方式",
                                      },
                                      model: {
                                        value: act.ext15,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext15", $$v)
                                        },
                                        expression: "act.ext15",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "收货人地址",
                                      prop: "ext17",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "收货人地址" },
                                      model: {
                                        value: act.ext17,
                                        callback: function ($$v) {
                                          _vm.$set(act, "ext17", $$v)
                                        },
                                        expression: "act.ext17",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.code !== "view"
                          ? _c(
                              "vxe-button",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addProduct(k, i)
                                  },
                                },
                              },
                              [_vm._v("添加产品")]
                            )
                          : _vm._e(),
                        _vm._l(
                          act.normalProductList,
                          function (product, productIndex) {
                            return _c(
                              "div",
                              {
                                key: productIndex,
                                staticClass: "product-box",
                                style:
                                  _vm.pIndex == productIndex
                                    ? "background-color:#f1f1f1"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseProduct(productIndex)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 21 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "支付产品",
                                                  prop: "productName",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "cost-select",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openProduct(
                                                          "product",
                                                          "product_list",
                                                          "materialCode",
                                                          k,
                                                          i,
                                                          null,
                                                          productIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        readonly: true,
                                                        placeholder: "支付产品",
                                                      },
                                                      model: {
                                                        value:
                                                          product.productName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            product,
                                                            "productName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.productName",
                                                      },
                                                    }),
                                                    _vm.code !== "view"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "clear-icon",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-circle-close",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.detletProduct(
                                                                      $event,
                                                                      k,
                                                                      i,
                                                                      productIndex
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "单价",
                                                  prop: "price",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder:
                                                      "公司主体+产品编码获取",
                                                  },
                                                  model: {
                                                    value: product.price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        product,
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.price",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "产品数量",
                                                  prop: "ext1",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "产品数量",
                                                    oninput:
                                                      "value=value.replace(/[^\\d]/g,'')",
                                                    maxLength: "8",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeCount(
                                                        $event,
                                                        k,
                                                        i,
                                                        productIndex
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: product.ext1,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        product,
                                                        "ext1",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.ext1",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "包装规格",
                                                  prop: "ext2",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder:
                                                      "根据所选产品获取",
                                                  },
                                                  model: {
                                                    value: product.ext2,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        product,
                                                        "ext2",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.ext2",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "产品件数",
                                                  prop: "ext3",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder:
                                                      "产品数量/包装规格",
                                                  },
                                                  model: {
                                                    value: product.ext3,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        product,
                                                        "ext3",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.ext3",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "产品金额",
                                                  prop: "ext4",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder: "单价*数量",
                                                  },
                                                  model: {
                                                    value: product.ext4,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        product,
                                                        "ext4",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "product.ext4",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                        },
                                        attrs: { span: 3 },
                                      },
                                      [
                                        productIndex > 0
                                          ? _c("el-button", {
                                              staticStyle: {
                                                "margin-left": "100px",
                                              },
                                              attrs: {
                                                type: "danger",
                                                icon: "el-icon-delete",
                                                circle: "",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteProductList(
                                                    k,
                                                    i,
                                                    productIndex
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }