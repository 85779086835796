import request from '@/found/utils/request';

export default class JudgeTime {
  constructor(props) {
    this.params = {
      actBeginDate: '',
      actEndDate: '',
      feeBudgetCodes: '',
      feeBudgetType: '',
      budgetSubjectsName: '',
      pageNum: 1,
      pageSize: -1,
    };
    if (props) {
      this.model = props.model;
      if (props.time && Array.isArray(props.time) && props.time.length === 2) {
        const startTime = props[0];
        const endTime = props[1];
        this.params = {
          actBeginDate: startTime,
          actEndDate: endTime,
          feeBudgetCodes: '',
          feeBudgetType: '',
          budgetSubjectsName: '',
          pageNum: 1,
          pageSize: -1,
        };
      }
    }
    this.getAuthBudget();
  }

  isControl = false

  update(props, callback) {
    if (!this.isControl) return;
    const bYear = new Date(this.params.actBeginDate).getFullYear();
    const bMonth = new Date(this.params.actBeginDate).getMonth();
    const bDay = new Date(this.params.actBeginDate).getDate();
    const eYear = new Date(this.params.actEndDate).getFullYear();
    const eMonth = new Date(this.params.actEndDate).getMonth();
    const eDay = new Date(this.params.actEndDate).getDate();
    const prev = [bYear, bMonth, bDay, eYear, eMonth, eDay];
    const cbYear = new Date(props.startTime).getFullYear();
    const cbMonth = new Date(props.startTime).getMonth();
    const cbDay = new Date(props.startTime).getDate();
    const ceYear = new Date(props.endTime).getFullYear();
    const ceMonth = new Date(props.endTime).getMonth();
    const ceDay = new Date(props.endTime).getDate();
    const cur = [cbYear, cbMonth, cbDay, ceYear, ceMonth, ceDay];
    let isDiff = true;
    for (const index in prev) {
      if (prev[index] !== cur[index]) {
        isDiff = false;
      }
    }
    this.params = {
      ...this.params,
      actBeginDate: props.startTime,
      actEndDate: props.endTime,
    };
    if (callback && typeof callback === 'function') {
      callback(isDiff);
    }
  }

  async getAuthBudget() {
    const res = await request.get('/mdm/mdmParameterController/getParameterValue', {
      parameterCode: 'budget_use_date_rule',
    });
    this.requestUrl = '/tpm/tpmFeeBudgetControlController/list';
    this.isControl = !(res.success && res.result === 'no_control');
  }

  static async getControlType() {
    const res = await request.get('/mdm/mdmParameterController/getParameterValue', {
      parameterCode: 'budget_use_date_rule',
    });
    return res.result;
  }

  // 获取费用预算
  async getDataList() {
    const url = '/tpm/tpmFeeBudgetControlController/list';
    const res = await request.post(url, this.params);
    return res.result.data;
  }

  // 获取大类
  setPropsOfCategories(controlIds, actType) {
    const categoriesCode = this.getRule('categoriesCode');
    categoriesCode.restful = '/tpm/tpmActController/getCategoriesByBudget';
    categoriesCode.restfulParams = { enableStatus: '009', controlIds, actType };
    categoriesCode.value = '';
  }

  async checkIsIn(id) {
    if (this.isControl) {
      const list = await this.getDataList();
      return list.find((i) => i.id === id);
    }
    return false;
  }
}
