<template>
  <div class="edit-table">
        <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <vxe-button v-if="code !=='view'" @click="addActivity" style="margin-bottom:10px;">新增活动大类</vxe-button>
      <div v-for="(item, k) in detailVos" :key="k" class="list-wrap">
        <el-form
          :model="item"
          :rules="detailRule"
          :ref="'detailForm'+k"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="7">
              <el-form-item label="预算科目" prop="name">
                <div class="cost-select" @click="openHandleClick('budgetSubjects', 'tpm_budget_subjects_list', 'budgetSubjectsCode', k, null,{subjectsTypeCode:'receive'})">
                  <el-input :readonly="true" v-model="item.ext19" placeholder="预算科目"></el-input>
                  <div class="clear-icon" v-if="code !=='view'">
                    <i class="el-icon-circle-close" @click="clearFn($event,'budgetSubjects',k)"></i>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="活动大类" prop="categoriesName">
                <div class="cost-select" @click="openHandleClick('category', 'tpm_category_list', 'categoriesCode', k, null,{budgetSubjectsCode:item.budgetSubjectsCode})">
                  <el-input :readonly="true" v-model="item.categoriesName" placeholder="请选择活动大类"></el-input>
                  <div class="clear-icon" v-if="code !=='view'">
                    <i class="el-icon-circle-close" @click="clearFn($event,'category',k)"></i>
                  </div>
                </div>

              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="活动细类" prop="fineName">
                <div class="cost-select" @click="openHandleClick('fine', 'tpm_fine_list', 'fineCode', k, null,{ categoriesCode: item.categoriesCode })">
                  <el-input :readonly="true" v-model="item.fineName" placeholder="请选择活动大类"></el-input>
                  <div class="clear-icon" v-if="code !=='view'">
                    <i class="el-icon-circle-close" @click="clearFn($event,'fine',k)"></i>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3" v-if="code !=='view'">
              <el-button type="danger" style="margin-left:100px" icon="el-icon-delete" circle size="mini" @click="deleteActivity(k)"></el-button>
            </el-col>
          </el-row>
          <vxe-button v-if="code !=='view'" @click="addDetail(k,item)" style="margin-bottom: 10px">新增明细</vxe-button>

          <el-tabs v-model="item.editableTabsValue" type="card" :closable="code !=='view'" @tab-remove="removeTab($event,k)">
            <el-tab-pane
              :key="act.tabValue"
              v-for="(act, i) in item.activityInfo"
              :label="'明细表单'+(i+1)"
              :name="act.tabValue"
              >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="活动明细名称" prop="ext1">
                    <el-input
                      v-model="act.ext1"
                      placeholder="活动明细名称"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="活动明细编码" prop="actDetailCode">
                    <el-input
                      disabled
                      v-model="act.actDetailCode"
                      placeholder="活动明细编码"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="公司主体" prop="ext3">
                  <div class="cost-select" @click="openHandleClick('company', 'company_list', 'companyCode', k, i,null)">
                    <el-input :readonly="true" v-model="act.ext3" placeholder="公司主体"></el-input>
                    <div class="clear-icon" v-if="code !=='view'">
                      <i class="el-icon-circle-close" @click="clearFn($event,'company',k,i)"></i>
                    </div>
                  </div>
                  </el-form-item>
                </el-col>

              </el-row>
              <!-- 活动明细信息 -->
              <el-row>
                <el-col :span="8">
                  <el-form-item label="受益经销商" prop="ext7">
                      <el-input :readonly="true" v-model="act.ext7" placeholder="受益经销商"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="受益组织" prop="ext5">
                    <div class="cost-select" @click="openHandleClick('org', 'org_list', 'orgCode', k, i,null)">
                      <el-input :readonly="true" v-model="act.ext5" placeholder="受益组织"></el-input>
                      <div class="clear-icon" v-if="code !=='view'">
                        <i class="el-icon-circle-close" @click="clearFn($event,'org',k,i)"></i>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="申请金额" prop="applyAmount">
                    <el-input
                      disabled
                      v-model="act.applyAmount"
                      placeholder="申请金额"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="活动明细内容" prop="ext13">
                    <el-input
                      v-model="act.ext13"
                      type="textarea"
                      autosize
                      placeholder="活动明细内容"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="收货人" prop="ext14">
                    <el-input
                      v-model="act.ext14"
                      placeholder="收货人"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系方式" prop="ext15">
                    <el-input
                      oninput="value=value.replace(/[^\d]/g,'')"
                      maxLength='11'
                      v-model="act.ext15"
                      placeholder="收货人联系方式"
                    ></el-input>
                  </el-form-item>
                </el-col>
                    <el-col :span="8">
                  <el-form-item label="收货人地址" prop="ext17">
                    <el-input
                      v-model="act.ext17"
                      placeholder="收货人地址"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <vxe-button v-if="code !=='view'" @click="addProduct(k,i)" style="margin-bottom: 10px">添加产品</vxe-button>
              <div
                  class="product-box"
                  :style="pIndex==productIndex?'background-color:#f1f1f1':''"
                  @click="chooseProduct(productIndex)" v-for="(product,productIndex) in act.normalProductList" :key="productIndex">
                <el-row>
                  <el-col :span="21">
                      <el-col :span="8">
                        <el-form-item label="支付产品"  prop="productName">
                          <div class="cost-select"  @click="openProduct('product', 'product_list', 'materialCode', k, i,null,productIndex)">
                            <el-input :readonly="true" v-model="product.productName" placeholder="支付产品"></el-input>
                            <div class="clear-icon" v-if="code !=='view'">
                              <i class="el-icon-circle-close" @click="detletProduct($event,k,i,productIndex)"></i>
                            </div>
                          </div>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="单价" prop="price">
                          <el-input
                            v-model="product.price"
                            disabled
                            placeholder="公司主体+产品编码获取"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <el-form-item label="产品数量" prop="ext1">
                          <el-input
                            @change="changeCount($event,k,i,productIndex)"
                            v-model="product.ext1"
                            placeholder="产品数量"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            maxLength='8'
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                          <el-form-item label="包装规格" prop="ext2">
                            <el-input
                              v-model="product.ext2"
                              disabled
                              placeholder="根据所选产品获取"
                            ></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="产品件数" prop="ext3">
                            <el-input
                              v-model="product.ext3"
                              disabled
                              placeholder="产品数量/包装规格"
                            ></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="产品金额" prop="ext4">
                            <el-input
                              v-model="product.ext4"
                              disabled
                              placeholder="单价*数量"
                            ></el-input>
                          </el-form-item>
                      </el-col>
                  </el-col>
                  <el-col :span="3" style="display: flex;align-items: center;justify-content: center;">
                       <el-button v-if="productIndex>0" type="danger" style="margin-left:100px" icon="el-icon-delete" circle size="mini" @click="deleteProductList(k,i,productIndex)"></el-button>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import _ from 'xe-utils';

export default {
  name: 'ContainOrg',
  props: {
    code: String,
    value: Array,
    disabled: Boolean,
    time: Object,
    controlIds: Array,
    saveRef: Function,
    budgetList: Array,
    default: () => ({
      controlIds: [],
      time: {},
      budgetList: [],
    }),
  },
  data() {
    return {
      index: '',
      aIndex: '',
      pIndex: 0,
      chooseModule: '',
      customerList: [],
      detailVos: [],
      detailForm: {},
      detailRule: {},
      selectRow: null,
      loading: false,
      beginDate: '',
      endDate: '',
      categoriesMapKeys: new Map(), // tab key信息
      tabName: '',
    };
  },
  components: {
    SelectConfig,
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.detailVos = JSON.parse(JSON.stringify(newVal || []));
      },
      deep: true,
      immediate: true,
    },
    time(n) {
      if (n) {
        this.beginDate = n.beginDate;
        this.endDate = n.endDate;
      }
    },
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  created() {

  },
  methods: {
    // 删除产品
    deleteProductList(index, aIndex, pIndex) {
      this.detailVos[index].activityInfo[aIndex].normalProductList.splice(pIndex, 1);
    },
    // 选择产品
    chooseProduct(index) {
      this.pIndex = index;
    },
    // 添加产品
    addProduct(k, i) {
      this.detailVos[k].activityInfo[i].normalProductList.push(
        {
          productCode: '', // 产品编码
          productName: '', // 产品名称
          price: '', // 产品单价
          ext1: '', // 产品数量
          ext2: '', // 包装规格
          ext3: '', // 产品件数
          ext4: '', // 产品金额,
        },
      );
    },
    // 删除产品
    detletProduct(e, index, aIndex, pIndex) {
      e.stopPropagation();
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].productCode = null;
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].productName = null;
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].price = '';
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext1 = '';
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext2 = '';
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext3 = '';
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext4 = '';
    },
    // 删除
    clearFn(e, code, index, aIndex) {
      e.stopPropagation();
      if (code === 'budgetSubjects') {
        this.detailVos[index].ext19 = null;
        this.detailVos[index].budgetSubjectsCode = null;
        this.detailVos[index].activityInfo = [];
        this.detailVos[index].categoriesCode = null;
        this.detailVos[index].categoriesName = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].fineCode = null;
      } else if (code === 'company') {
        this.detailVos[index].activityInfo[aIndex].ext2 = null;
        this.detailVos[index].activityInfo[aIndex].ext3 = null;
        this.detailVos[index].activityInfo[aIndex].ext7 = null;
        this.detailVos[index].activityInfo[aIndex].ext6 = null;
        this.detailVos[index].activityInfo[aIndex].normalProductList.forEach((item) => {
          item.price = 0;
          item.ext4 = 0;
        });
        this.detailVos[index].activityInfo[aIndex].normalProductList = [
          {
            productCode: '', // 产品编码
            productName: '', // 产品名称
            price: '', // 产品单价
            ext1: '', // 产品数量
            ext2: '', // 包装规格
            ext3: '', // 产品件数
            ext4: '', // 产品金额,
          },
        ];
      } else if (code === 'org') {
        this.detailVos[index].activityInfo[aIndex].ext4 = null;
        this.detailVos[index].activityInfo[aIndex].ext5 = null;
      } else if (code === 'category') {
        this.detailVos[index].categoriesCode = null;
        this.detailVos[index].categoriesName = null;
        this.detailVos[index].activityInfo = [];
      } else if (code === 'fine') {
        this.detailVos[index].fineName = null;
        this.detailVos[index].fineCode = null;
        this.detailVos[index].activityInfo = [];
      }
    },

    // 改变数量
    changeCount(val, index, aIndex, pIndex) {
      this.calcPrice(index, aIndex, pIndex);
      this.calNumber(index, aIndex, pIndex);
    },

    // 计算价格
    calcPrice(index, aIndex, pIndex) {
      const count = Number(this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext1 || 0); // 数量
      const price = Number(this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].price || 0); // 单价
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext4 = _.multiply(count, price);
    },

    // 计算件数
    calNumber(index, aIndex, pIndex) {
      const count = Number(this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext1 || 0); // 数量
      const unitConversion = Number(this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext2 || 0); // 规格
      this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].ext3 = _.divide(count, unitConversion).toFixed(2);
      this.$forceUpdate();
    },

    // 询价
    getPrice(cCode, pCode, index, aIndex, pIndex) {
      if (cCode && pCode) {
        if (!_.isNull(pIndex)) {
          request.post('/mdm/mdmPriceSettingExtController/searchAllPrice', {
            companyCode: cCode,
            priceTypeEnum: 'P001',
            productCode: pCode,
            num: 1,
            priceSettingType: '04',
          }).then((res) => {
            if (res.success) {
              const tempRes = (res.result || {}).itemResultVoList || [];
              this.detailVos[index].activityInfo[aIndex].normalProductList[pIndex].price = (tempRes && tempRes.length > 0) ? (tempRes[0].price || 0) : 0;
              this.calcPrice(index, aIndex, pIndex);
            }
          });
        }
      }
    },

    // 根据活动大类获取活动细类
    async getSubcategoryList(code, index) {
      const res = await request.post('/tpm/tpmCostTypeCategoriesExt/getFineByCategories', { categoriesCode: code });
      this.detailVos[index].ext80 = res.result || [];
    },

    // 改变细类，根据选择的细类中的costFormCode，即表单名称，来判断引用哪个活动明细表单
    changSubcategory(val, index) {
      this.detailVos[index].activityInfo = [];
      if (val) {
        for (const item of this.detailVos[index].ext80) {
          if (item.fineCode === val) {
            this.detailVos[index].ext68 = item.costFormCode;
            this.detailVos[index].fineName = item.fineName;
          }
        }
      } else {
        this.detailVos[index].ext68 = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].fineCode = null;
      }
    },

    // 打开点击弹窗
    openHandleClick(m, list, key, index, aIndex, paramData) {
      console.log(m);
      if (this.code === 'view') {
        return;
      }
      this.index = index;
      this.aIndex = aIndex;
      this.chooseModule = m;
      let params = {};
      if (m === 'company' || m === 'org') {
        params = {
          functionCode: list,
          data: [],
          idKey: key,
          paramData: {
            enableStatus: '009',
          },
        };
      } else {
        params = {
          functionCode: list,
          data: [],
          idKey: key,
          paramData,
        };
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 产品弹窗
    openProduct(m, list, key, index, aIndex, paramData, pIndex) {
      if (this.code === 'view') {
        return;
      }
      this.index = index;
      this.aIndex = aIndex;
      this.pIndex = pIndex;
      this.chooseModule = m;
      const params = {
        functionCode: list,
        data: [],
        idKey: key,
        paramData: {
          isShelf: 'Y',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },

    // 获取弹窗选中数据
    async onGetSelect(data) {
      const { index, aIndex } = this;
      if (this.chooseModule === 'budgetSubjects') { // 预算科目
        if (data.length > 0) {
          this.detailVos[index].budgetSubjectsCode = data[0].budgetSubjectsCode;
          this.detailVos[index].ext19 = data[0].budgetSubjectsName;
        } else {
          this.detailVos[index].budgetSubjectsCode = null;
          this.detailVos[index].ext19 = null;
        }

        this.detailVos[index].categoriesCode = null;
        this.detailVos[index].categoriesName = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].fineCode = null;
        this.detailVos[index].activityInfo = [];
      } else if (this.chooseModule === 'org') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext5 = data[0].orgName; // 受益组织名称
          this.detailVos[index].activityInfo[aIndex].ext4 = data[0].orgCode; // 受益组编码
        } else {
          this.detailVos[index].activityInfo[aIndex].ext5 = null;
          this.detailVos[index].activityInfo[aIndex].ext4 = null;
        }
      } else if (this.chooseModule === 'product') { // 兑付产品
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].productCode = data[0].materialCode;
          this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].productName = data[0].materialName;
          this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].ext2 = data[0].unitConversion;
          this.calNumber(index, aIndex, this.pIndex);
          this.getPrice(this.detailVos[index].activityInfo[aIndex].ext2, data[0].materialCode, index, aIndex, this.pIndex);
        } else {
          this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].productCode = null;
          this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].productName = null;
          this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].price = 0;
        }
      } else if (this.chooseModule === 'company') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext2 = data[0].companyCode;
          this.detailVos[index].activityInfo[aIndex].ext3 = data[0].companyName;
          this.detailVos[index].activityInfo[aIndex].ext7 = data[0].companyName; // 受益经销商名称
          this.detailVos[index].activityInfo[aIndex].ext6 = data[0].insideCustomerCode; // 编码
          this.getPrice(this.detailVos[index].activityInfo[aIndex].ext2, this.detailVos[index].activityInfo[aIndex].normalProductList[this.pIndex].productCode, index, aIndex, this.pIndex);
        } else {
          this.detailVos[index].activityInfo[aIndex].ext2 = null;
          this.detailVos[index].activityInfo[aIndex].ext3 = null;
          this.detailVos[index].activityInfo[aIndex].ext7 = null;// 受益经销商名称
          this.detailVos[index].activityInfo[aIndex].ext6 = null;
        }
        this.detailVos[index].activityInfo[aIndex].normalProductList = [
          {
            productCode: '', // 产品编码
            productName: '', // 产品名称
            price: '', // 产品单价
            ext1: '', // 产品数量
            ext2: '', // 包装规格
            ext3: '', // 产品件数
            ext4: '', // 产品金额,
          },
        ];
      } else if (this.chooseModule === 'category') { // 选择大类
        if (data.length > 0) {
          this.detailVos[index].categoriesCode = data[0].categoriesCode;
          this.detailVos[index].categoriesName = data[0].categoriesName;
        } else {
          this.detailVos[index].categoriesCode = null;
          this.detailVos[index].categoriesName = null;
        }
        this.detailVos[index].fineName = null;
        this.detailVos[index].fineCode = null;
        this.detailVos[index].activityInfo = [];
      } else if (this.chooseModule === 'fine') { // 选择细类
        if (data.length > 0) {
          this.detailVos[index].fineName = data[0].fineName;
          this.detailVos[index].fineCode = data[0].fineCode;
        } else {
          this.detailVos[index].fineName = null;
          this.detailVos[index].fineCode = null;
        }
        this.detailVos[index].activityInfo = [];
      }
    },

    // 删除活动
    deleteActivity(index) {
      this.$confirm('确定要删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.detailVos.splice(index, 1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },

    // 新增活动类型
    addActivity() {
      const { detailVos } = this;
      detailVos.push({
        categoriesName: '',
        categoriesCode: '',
        ext19: '', // 预算科目名称
        budgetSubjectsCode: '',
        fineCode: '',
        fineName: '',
        activityInfo: [],
      });
      this.detailVos = [];
      this.detailVos = JSON.parse(JSON.stringify(detailVos));
      this.$emit('input', this.detailVos);
    },

    // 新增明细
    addDetail(index, item) {
      if (item.fineCode) {
        this.detailVos[index].editableTabsValue = `activity${index}-1`;
        this.detailVos[index].activityInfo.push({
          ext3: null, // 公司主体名称
          ext2: null, // 公司主体编码
          actDetailCode: null,
          ext1: null, // 活动明细名称
          ext6: null, // 受益经销商编码
          ext7: null, // 受益经销商名称
          ext4: null, // 受益组织编码
          ext5: null, // 受益组织名称
          applyAmount: null, // 申请金额
          ext37: null, // 执行次数
          ext14: null, // 收货人
          ext15: null, // 收货人联系方式
          ext17: null, // 收货地址
          ext13: null, //
          normalProductList: [
            {
              productCode: '', // 产品编码
              productName: '', // 产品名称
              price: '', // 产品单价
              ext1: '', // 产品数量
              ext2: '', // 包装规格
              ext3: '', // 产品件数
              ext4: '', // 产品金额,
            },
          ], // 产品列表
        });
        /* eslint-disable */
      this.detailVos[index].activityInfo.forEach((aItem,a) => {
        aItem.tabValue = `activity${index}-${a+1}`;
      });
      } else {
        this.$message.warning('请先选择活动细类');
      }
      this.$emit('input', this.detailVos);
    },


    // 移出tabs
    removeTab(targetName, index) {
       this.$confirm('确定要删除吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         let tabs =  this.detailVos[index].activityInfo;
            let activeName = this.detailVos[index].editableTabsValue;
            if (activeName === targetName) {
              tabs.forEach((tab, index) => {
                if (tab.tabValue === targetName) {
                  let nextTab = tabs[index + 1] || tabs[index - 1];
                  if (nextTab) {
                    activeName = nextTab.tabValue;
                  }
                }
              });
            }
            this.detailVos[index].activityInfo = tabs.filter(tab => tab.tabValue !== targetName);
            if(this.detailVos[index].activityInfo.length===1){
                this.detailVos[index].editableTabsValue = this.detailVos[index].activityInfo[0].tabValue
            }else{
              this.detailVos[index].editableTabsValue = activeName;
            }
            this.$emit('input', this.detailVos);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },

    // 返回数据
    backData() {
      return this.detailVos;
    },
  },
};
</script>

<style lang="less" scoped>
.product-box{
border-bottom:1px solid #eeeeee;padding:20px 0;

}
.edit-table {
  padding-left: 50px;
}
.list-wrap {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;

}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
