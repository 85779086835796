import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import JudgeTime from '../utils';
import RelationFineGroup, { BudgetSubjectTable } from '../components';

formCreate.component('RelationFine', RelationFineGroup);
formCreate.component('BudgetSubjectForm', BudgetSubjectTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      isControl: null,
      formFunctionCode: 'tpm_activity_project_form',
      formParentCode: 'CRM20210928000002729',
      actType: 'department_receive',
      rule: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        }],
      },
      fData: {},
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },

  async created() {
    const { row } = this.formConfig;
    if (row && row.id) {
      this.init();
    }
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      //
      if (v.field === 'actTime') { // 活动时间
        v.props = {
          ...v.props,
          type: 'datetimerange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
        };
      }

      if (v.field === 'relationFine') {
        v.props = {
          ...v.props,
          saveRef: this.saveRef,
          code: this.formConfig.code,
        };
        v.validate = [
          {
            trigger: 'change',
            required: true,
            message: '请填写明细信息！',
          },
        ];
      }
      return v;
    },

    // 时间格式化
    getTime(startTime, endTime) {
      return {
        startTime,
        endTime,
        beginDate: startTime.indexOf(' ') ? startTime.split(' ')[0] : '',
        beginDateSecond: startTime.indexOf(' ') ? startTime.split(' ')[1] : '',
        endDate: endTime.indexOf(' ') ? endTime.split(' ')[0] : '',
        endDateSecond: endTime.indexOf(' ') ? endTime.split(' ')[1] : '',
      };
    },

    // 格式化
    formateTime(val) {
      let time = null;
      if (val && Array.isArray(val) && val.length === 2) {
        const {
          beginDate, beginDateSecond, endDate, endDateSecond,
        } = this.getTime(val[0], val[1]);
        time = {
          beginDate, beginDateSecond, endDate, endDateSecond,
        };
      }
      return time;
    },

    // 格式化明细
    async formateDetail(data) {
      const detailVos = [];
      data.forEach((item) => {
        item.activityInfo.forEach((act) => {
          detailVos.push({
            budgetSubjectsName: item.budgetSubjectsName,
            budgetSubjectsCode: item.budgetSubjectsCode,
            categoriesName: item.categoriesName,
            categoriesCode: item.categoriesCode,
            fineCode: item.fineCode,
            fineName: item.fineName,
            ext19: item.ext19,
            ...act,
          });
        });
      });
      return detailVos;
    },

    // 提交
    async submit(type) {
      let params = this.getFormData();
      if (!params) return false;
      let url = '/tpm/tpmActController/save';

      const { row, code } = this.formConfig;
      const { actTime, budget, relationFine } = params;
      // 时间处理
      if (actTime) {
        const time = this.formateTime(actTime);
        if (time) {
          params = { ...params, ...time };
        }
      }

      if (relationFine && relationFine.length === 0) {
        this.$message({
          type: 'error',
          message: '请填写活动明细',
        });
        return false;
      }

      params.actType = this.actType;

      // 明细
      const detail = await this.fApi.method('relationFine', 'backData')();

      params.detailVos = await this.formateDetail(detail);
      // 文件处理
      params.ext11 = JSON.stringify(params.ext11 || []);

      delete params.budget;
      delete params.relationFine;
      delete params.actTime;
      params.ext38 = 'N';
      if (code === 'edit') {
        url = '/tpm/tpmActController/update';
        params = { ...params, id: row.actId || row.id, actCode: row.actCode };
        if (type === 1) {
          params.saveType = 3;
        } else {
          params.saveType = 4;
        }
      } else {
        params.saveType = type;
      }

      // 跳转下一步
      if (type === 2) {
        this.$emit('submit', {
          row: params,
          submitUrl: url,
        });
      } else {
        const { success } = await request.post(url, params);
        if (success) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      }
    },

    // 获取明细map
    getDetail(data) {
      const { detailVos } = data;
      const detail = [];
      if (detailVos && detailVos.length) {
        detailVos.forEach((subItem, index) => {
          const item = subItem;
          if (detail.length === 0) {
            detail.push({
              categoriesName: item.categoriesName,
              categoriesCode: item.categoriesCode,
              ext19: item.ext19, // 预算科目名称
              budgetSubjectsCode: item.budgetSubjectsCode,
              fineCode: item.fineCode,
              fineName: item.fineName,
              activityInfo: [{
                ...item,
                tabValue: `activity${index}-1`,
              }],
              editableTabsValue: `activity${index}-1`, // 激活的选项
            });
          } else {
            let flag = false;
            detail.forEach((act, aIndex) => {
              if (act.categoriesCode === item.categoriesCode && act.fineCode === item.fineCode) {
                flag = true;
                detail[aIndex].activityInfo.push({
                  ...subItem,
                  tabValue: `activity${index}-${aIndex + 1}`,
                });
              }
            });
            if (!flag) {
              detail.push({
                categoriesName: item.categoriesName,
                categoriesCode: item.categoriesCode,
                ext19: item.ext19, // 预算科目名称
                budgetSubjectsCode: item.budgetSubjectsCode,
                fineCode: item.fineCode,
                fineName: item.fineName,
                activityInfo: [{
                  ...item,
                  tabValue: `activity${index}-1`,
                }],
                editableTabsValue: `activity${index}-1`, // 激活的选项
              });
            }
          }
        });
      }
      return detail;
    },
    // 详情 初始化数据
    async init() {
      const { row } = this.formConfig;
      const data = await request.get('/tpm/tpmActController/query', { id: row.actId || row.id }).then((res) => (res.success ? res.result : []));

      // 处理时间
      data.actTime = [`${data.beginDate} ${data.beginDateSecond}`, `${data.endDate} ${data.endDateSecond}`];

      if (data.ext11) {
        data.ext11 = JSON.parse(data.ext11) || [];
      } else {
        data.ext11 = [];
      }

      data.relationFine = this.getDetail(data);

      this.setValue(data);
    },
  },
};
